import React from "react"
import "./about.scss"
import AboutLogo from "./aboutLogo.components"
import { withTranslation } from "react-i18next"
// import { aboutHome } from "../../../data/staticData"
import "react-alice-carousel/lib/alice-carousel.css"
import TalkToOurTeam from "./TalkToOurTeam"

const About = props => {
  const { t } = props
  return (
    <div className="container">
      <div
        className="cemetery__title in-view"
        style={{ paddingBottom: "2rem" }}
      >
        <br />
        <br />
        <br />
        <h2>{t("src.pages.homePage.about.title")}</h2>
        <p>{t("src.pages.homePage.about.content")}</p>
      </div>

      <AboutLogo />

      <TalkToOurTeam clicked={props.clicked} />
    </div>
  )
}

export default withTranslation()(About)
