import React from "react"
import { withTranslation } from "react-i18next"
import "./offices.scss"
// import { officesHome } from "../../../data/staticData"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const Offices = props => {
  const { t } = props
  return (
    <section className="offices in-view" style={{ paddingTop: "70px" }}>
      <div className="container">
        <div className="offices__title">
          <h2>{t("src.pages.homePage.offices.title")}</h2> <br />
          <p>{t("src.pages.homePage.offices.content")}</p>
        </div>
        <div
          id="officesSlider"
          className="offices-slider owl-carousel owl-loaded owl-drag"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 0s ease 0s"
              }}
            >
              <div className="owl-item active">
                <div className="offices-slider__item pic1">
                  <div className="offices-slider__content">
                    <div className="offices-slider__overlay"></div>
                    <div className="offices-slider__text">
                      <div className="offices-slider__city">
                        VIET NAM
                        <br />
                        HO CHI MINH
                      </div>
                      <div className="offices-slider__address">
                        <p>{t("src.pages.homePage.offices.address1")}</p>
                        <p>{t("src.pages.homePage.offices.phone1")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item active">
                <div className="offices-slider__item pic2">
                  <div className="offices-slider__content">
                    <div className="offices-slider__overlay"></div>
                    <div className="offices-slider__text">
                      <div className="offices-slider__city">
                        VIET NAM
                        <br />
                        NHA TRANG
                      </div>
                      <div className="offices-slider__address">
                        <p>{t("src.pages.homePage.offices.address2")}</p>
                        <p>{t("src.pages.homePage.offices.phone2")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="owl-item active">
                <div className="offices-slider__item pic3">
                  <div className="offices-slider__content">
                    <div className="offices-slider__overlay"></div>
                    <div className="offices-slider__text">
                      <div className="offices-slider__city">
                        VIET NAM
                        <br />
                        DA NANG
                      </div>
                      <div className="offices-slider__address">
                        <p>{t("src.pages.homePage.offices.address3")}</p>
                        <p>{t("src.pages.homePage.offices.phone3")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* carousel */}
          <AliceCarousel className="officeCarousel">
            <div className="owl-item active">
              <div className="offices-slider__item pic1">
                <div className="offices-slider__content">
                  <div className="offices-slider__overlay"></div>
                  <div className="offices-slider__text">
                    <div className="offices-slider__city">
                      VIET NAM
                      <br />
                      HO CHI MINH
                    </div>
                    <div className="offices-slider__address">
                      <p>{t("src.pages.homePage.offices.address1")}</p>
                      <p>{t("src.pages.homePage.offices.phone1")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="owl-item active">
              <div className="offices-slider__item pic2">
                <div className="offices-slider__content">
                  <div className="offices-slider__overlay"></div>
                  <div className="offices-slider__text">
                    <div className="offices-slider__city">
                      VIET NAM
                      <br />
                      NHA TRANG
                    </div>
                    <div className="offices-slider__address">
                      <p>{t("src.pages.homePage.offices.address2")}</p>
                      <p>{t("src.pages.homePage.offices.phone2")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="owl-item active">
              <div className="offices-slider__item pic3">
                <div className="offices-slider__content">
                  <div className="offices-slider__overlay"></div>
                  <div className="offices-slider__text">
                    <div className="offices-slider__city">
                      VIET NAM
                      <br />
                      DA NANG
                    </div>
                    <div className="offices-slider__address">
                      <p>{t("src.pages.homePage.offices.address3")}</p>
                      <p>{t("src.pages.homePage.offices.phone3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AliceCarousel>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Offices)
