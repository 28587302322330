import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import "./offer.scss"
import {
  DATASOLUTIONS,
  DATAINDUSTRIES,
  DATASERVICES
} from "./../../../data/staticData"
import SolutionItem from "./offerItem/solutionItem"
import ServicesItem from "./offerItem/servicesItem"
import IndustriesItem from "./offerItem/industriesItem"

class Offer extends Component {
  state = {
    activeSolution: true,
    activeServices: false,
    activeIndustries: false,
    toggleClassSolution: "active",
    toggleClassServices: "",
    toggleClassIndustries: ""
  }

  activeSolutionOffer = () => {
    this.setState({
      activeSolution: true,
      activeServices: false,
      activeIndustries: false,
      toggleClassSolution: "active",
      toggleClassIndustries: "",
      toggleClassServices: ""
    })
  }
  activeServicesOffer = () => {
    this.setState({
      activeSolution: false,
      activeServices: true,
      activeIndustries: false,
      toggleClassServices: "active",
      toggleClassIndustries: "",
      toggleClassSolution: ""
    })
  }
  activeIndustriesOffer = () => {
    this.setState({
      activeSolution: false,
      activeServices: false,
      activeIndustries: true,
      toggleClassIndustries: "active",
      toggleClassServices: "",
      toggleClassSolution: ""
    })
  }

  render() {
    const { t } = this.props
    const dataSolutions = t("src.pages.homePage.offer.dataSolutions")
    const dataServices = t("src.pages.homePage.offer.dataServices")
    const dataIndustries = DATAINDUSTRIES
    const {
      toggleClassSolution,
      toggleClassIndustries,
      toggleClassServices
    } = this.state
    return (
      <section className="section-offers" style={{ marginTop: "50px" }}>
        <div id="particles" style={{ width: "100%", height: "821px" }}>
          <canvas
            id="canvas"
            width="1905"
            height="821"
            style={{ width: "100%", height: "100%" }}
          ></canvas>
        </div>
        <div className="coverTitleOffers">
          <div className="offers">
            <div className="offers-title">
              <h2 className="title title--black">
                {" "}
                {t("src.pages.homePage.offer.offerTitle")}{" "}
              </h2>
            </div>
            <div className="offers-tab" style={{ margin: "60px auto" }}>
              <h4
                id="solutions-title"
                activeclassname="offers-tablinks active"
                className={`offers-tablinks ${toggleClassSolution}`}
                data-name="solutions"
                onClick={this.activeSolutionOffer}
              >
                {t("src.pages.homePage.offer.solutionsTitle")}
              </h4>
              <h4
                id="services-title"
                activeclassname="offers-tablinks active"
                className={`offers-tablinks ${toggleClassServices}`}
                data-name="services"
                onClick={this.activeServicesOffer}
              >
                {t("src.pages.homePage.offer.servicesTitle")}
              </h4>
              {/* <h4 id="industries-title" activeclassname="offers-tablinks active" className= {`offers-tablinks ${toggleClassIndustries}`} data-name="industries" onClick = {this.activeIndustriesOffer}>
                Industries
              </h4> */}
            </div>

            <SolutionItem
              dataSolutions={dataSolutions}
              activeSolution={this.state.activeSolution}
            />
            <ServicesItem
              dataServices={dataServices}
              activeServices={this.state.activeServices}
            />
            {/* <IndustriesItem dataIndustries={dataIndustries} activeIndustries={this.state.activeIndustries}/> */}
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(Offer)
