import React from "react"
import { ChevronRight } from "react-feather"
import { withTranslation } from "react-i18next"

const ViewMoreBtn = props => {
  const { t } = props
  return (
    <span className="item__more">
      {t("src.pages.servicesPage.viewMore")}
      <ChevronRight className="rightArr" href="#/" size="15" />
    </span>
  )
}

export default withTranslation()(ViewMoreBtn)
