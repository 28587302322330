import React, { Component } from "react"

import "./homepage.scss"
import HomePageTitle from "./homepageTitle/homepageTitle.component"
import Offices from "./offices/offices.component"
// import News from "./news/news.component"
import Clients from "./clients/clients.component"
import About from "./about/about.component"
import Offer from "./offer/offer"
// import Contact from "./contact/contact.component"

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.statsRef = React.createRef()
  }
  handleScroll = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef.current.offsetTop,
      behavior: "smooth"
    })
  }
  render() {
    return (
      <div>
        <HomePageTitle />
        <div className="container-fluid">
          <About />
          <Offer />
          <Clients />
          {/* <Contact /> */}
          {/* <div ref={this.statsRef}>
            <News />
          </div> */}
          <Offices />
        </div>
      </div>
    )
  }
}

export default HomePage
