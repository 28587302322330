import React from "react"
import { ChevronRight } from "react-feather"
import { Slide } from "react-slideshow-image"
// import { dataHomepageTitle } from "../../../data/staticData"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"
import "./homepageTitle.scss"

const HomePageTitle = props => {
  const { t } = props
  const properties = {
    duration: 10000,
    transitionDuration: 300,
    infinite: true,
    indicators: true,
    arrows: true
  }
  return (
    <section className="homeTitle" style={{ position: "relative" }}>
      <Slide {...properties}>
        {t("src.pages.homePage.dataHomepageTitle").map((block, idx) => (
          <div key={idx} className="each-fade">
            <div className={`imgHome home${idx + 1}`}>
              <div className="coverContentHometitle">
                <h2>{block.text1}</h2>
                <p>{block.text2}</p>
                {/* <div className="readMoreDiv">
                <Link to="/" onClick={props.clicked}>
                  Read more
                  <div
                    style={{
                      paddingLeft: "12px",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <ChevronRight className="rightArr" href="#/" size="20" />
                  </div>
                </Link>
              </div> */}
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </section>
  )
}

export default withTranslation()(HomePageTitle)
