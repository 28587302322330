import React, { Component } from "react"
import "./clients.scss"
import AliceCarousel from "react-alice-carousel"
// import { clientHome } from "../../../data/staticData"
import { Link } from "gatsby"
import { ChevronRight } from "react-feather"
import { withTranslation } from "react-i18next"
import ViewMoreBtn from "../../servicesPage/servicesHomePage/servicesCustom/viewMorebtn"

class Clients extends Component {
  render() {
    const { t } = this.props
    const dataClient = t("src.pages.homePage.clients")
    // console.log(da)
    const opptions = {
      autoPlay: true,
      autoPlayInterval: 3000,
      buttonsDisabled: true,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 1
        },
        1024: {
          items: 1
        } // number of elements in the slide
      },
      stagePadding: {
        paddingLeft: 0, // in pixels
        paddingRight: 0
      },
      mouseDragEnabled: true
    }
    return (
      <section
        className="clients-success in-view"
        style={{ backgroundColor: "#cecece29" }}
      >
        <div className="container">
          <div className="coverTitleClients">
            <h2 className="title title--black">
              {t("src.pages.homePage.clientTitle")}{" "}
            </h2>
            {/* <Link className="item__more" to="/">
              all case studies
              <ChevronRight className="rightArr" href="#/" size="17" />
            </Link> */}
          </div>
          <div className="coverAliceClients">
            <AliceCarousel {...opptions}>
              {dataClient.map((block, index) => (
                <div key={index} className="slide-wrapper">
                  <div className="content">
                    <h3 className="title">{block.title}</h3>
                    <p className="caption caption_appear">{block.content}</p>
                    <Link to="/" className="aViewMoreClients">
                      <ViewMoreBtn />
                    </Link>
                  </div>
                  <div className="content-client">
                    <figure
                      className={`clients-image clients${index + 1}`}
                    ></figure>
                  </div>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(Clients)
