import React from "react"
import { ChevronRight } from "react-feather"
import { Link } from "gatsby"
// import { aboutHome } from "../../../data/staticData"
import { withTranslation } from "react-i18next"

const TalkToOurTeam = props => {
  const { t } = props
  return (
    <div className="talk column column--flex-center">
      <div className="coverTalk">
        <Link
          to="/contactus"
          className="button button--clear button--blue"
          onClick={props.clicked}
        >
          {t("src.pages.homePage.about.btn")}
        </Link>
        <ChevronRight href="/" onClick={props.clicked} />
      </div>
    </div>
  )
}

export default withTranslation()(TalkToOurTeam)
